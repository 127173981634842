<template>
	<v-container>
		<v-row justify="start">
			<v-col cols="3" md="1">
				<v-btn
					depressed
					color="primary"
					class="text-capitalize secondary--text radius-10 mb-0"
					@click="$router.push({path: '/order-histories/'})"
				>
					Back
				</v-btn>
			</v-col>
			<v-spacer></v-spacer>
			<v-col
				v-if="this.sd_list[0] && this.hasPaid" 
				class="mr-10" 
				cols="3" 
				md="1"
			>
				<v-btn
					depressed
					color="primary"
					class="text-capitalize secondary--text radius-10 mb-0"
					@click="reorder()"
				>
					Reorder
				</v-btn>
			</v-col>
			<v-col
				class="mr-5" 
				v-if="!this.hasPaid" 
				cols="3" 
				md="2"
				lg="1"
			>
				<v-btn
					depressed
					outlined
					color="transparent"
					class="text-capitalize secondary--text radius-10 mb-0"
					@click="confrimCancelOrder()"
				>
					Cancel 
				</v-btn>
			</v-col>
			<v-col
				class="mr-10" 
				v-if="!this.hasPaid" 
				cols="3"  
				md="2"
				lg="1"
			>
				<v-btn
					depressed
					color="primary"
					class="text-capitalize secondary--text radius-10 mb-0"
					@click="payNow()"
				>
					Pay Now
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="10">
				<v-row>
					<v-col cols="12" md="5" class="py-0">
						<v-row 
							no-gutters
							class="overflow-auto pt-3"
						>
							<v-col 
								cols="12"
								class="my-2"
							>
								<span>Vehicle Information</span>
								<p v-if="sd" class="font-weight-black mt-3 pb-1">
									- {{ (sd.maker ? sd.maker.name + ' ' : '') + (sd.model_name ? sd.model_name + ' ' : '') + (sd.year ? sd.year : '') }}
								</p>
								<p v-else class="font-weight-black mt-3 pb-1">
									-
								</p>
								<v-divider></v-divider>
							</v-col>
						</v-row>
						<v-row 
							no-gutters
							class="overflow-auto pt-3"
						>
							<v-col 
								cols="12"
								class="my-2"
							>
								<span>DK Code</span>
								<p class="font-weight-black mt-3 pb-1">
									- {{ sd ? sd.dk_code : '' }}
								</p>
								<v-divider></v-divider>
							</v-col>							
						</v-row>
						<v-row 
							no-gutters
							class="overflow-auto pt-3"
						>
							<v-col 
								cols="12"
								class="my-2"
							>
								<span>Package</span>
								<p v-if="order_details" class="font-weight-black mt-3 pb-1">
									- {{ order_details.package ? order_details.package.name : '' }}
								</p>

								<p v-else class="font-weight-black mt-3 pb-1">
									-
								</p>
								<v-divider></v-divider>
							</v-col>							
						</v-row>
						<v-row 
							no-gutters
							class="overflow-auto pt-3"
						>
							<v-col 
								cols="12"
								class="my-2"
							>
								<span>Main Color</span>
								<v-container
									v-if="order_details.main_color"
									style="align-items:center; display:flex;"
									class="pl-0"
								>
									<div
										v-ripple
										style="height: 35px;width: 35px;"
										class="mr-3"
										:style="'background:'+computedRGBA(order_details.main_color) +';  display:inline-block;'"
									>
									</div>
									<div 
										style="display:inline-block;"
									>
										{{ order_details.main_color.name + ' - ' + order_details.main_color.description }}
									</div>
								</v-container>
								<p 
									v-else
									class="font-weight-black mt-3 pb-1"
								>
									- None
								</p>
								
								<v-divider></v-divider>
							</v-col>	
						</v-row>
						<v-row 
							no-gutters
							class="overflow-auto pt-3"
						>
							<v-col 
								cols="12"
								class="my-2"
							>
								<span>Contrast Color</span>
								<v-container
									v-if="order_details.secondary_color"
									style="align-items:center; display:flex;"
									class="pl-0"
								>
									<div
										v-ripple
										style="height: 35px;width: 35px;"
										class="mr-3"
										:style="'background:'+ computedRGBA(order_details.secondary_color) + ';  display:inline-block;'"
									>
									</div>
									<div 
										style="display:inline-block;"
									>
										{{ order_details.secondary_color.name + ' - ' + order_details.secondary_color.description }}
									</div>
								</v-container>
								<p 
									v-else
									class="font-weight-black mt-3 pb-1"
								>
									- None
								</p>						
								<v-divider></v-divider>
							</v-col>	
						</v-row>
						<v-row 
							no-gutters
							class="pt-3"
						>
							<v-col 
								cols="12"
								class="my-2"
							>
								<span>Seam Type & Seam Color</span>
								<!-- <v-container
									style="align-items:center; display:flex;"
									class="pl-0"
									v-if="order_details"
								>
									<div
										style="display:inline-block; width: 35px; height: 35px;"
									>
										<canvas id="seamWindow"></canvas>				
									</div>
									<div 
										style="display:inline-block;"
										v-if="order_details.seam"
										class="ml-2"
									>
										{{ order_details.seam.name  + (order_details.piping_color ? ( ' - ' + order_details.piping_color.name) : '') + (order_details.piping_color ? (' ' + order_details.piping_color.description) : '') }}
									</div>
								</v-container> -->
								<v-container
									v-if="order_details"
									style="align-items:center; display:flex;"
									class="pl-0"
								>
									<v-row>
										<v-col cols="12" lg="3" class="pr-1">
											<div
												v-ripple
												style="height: 100px;width: 100px;"
											>
												<canvas id="seamWindow"></canvas>
											</div>
										</v-col>
										<v-col cols="12" lg="9">
											<v-row>
												<v-col v-if="order_details.seam" class="pt-3">
													<span>Seam Type: </span>
													<span class="font-weight-black">{{ order_details.seam.name }}</span>
												</v-col>
											</v-row>
											<v-row>
												<v-col class="pt-1">
													<span>Seam Color: </span>
													<span class="font-weight-black">{{ order_details.seam_color ? (order_details.seam_color.name + ' ' + order_details.seam_color.description) : '-' }}</span>
												</v-col>
											</v-row>
											<v-row>
												<v-col class="pt-1">
													<span>Piping Color: </span>
													<span class="font-weight-black">{{ order_details.piping_color ? (order_details.piping_color.name + ' ' + order_details.piping_color.description) : '-' }}</span>
												</v-col>
											</v-row>
										</v-col>
									</v-row>
								</v-container>
								
								<v-divider></v-divider>
							</v-col>	
						</v-row>
					</v-col>
					<v-col cols="12" md="5" class="py-0">
						<v-row 
							no-gutters
							class="overflow-auto pt-3"
						>
							<v-col 
								cols="12"
								class="my-2"
							>
								<span>Logo</span>
								<v-container
									v-if="order_details.seat_logo"
									style="align-items:center; display:flex;"
									class="pl-0"
								>
									<v-row>
										<v-col cols="3" class="pr-1">
											<div
												v-ripple
												style="height: 100px;width: 100px;"
											>
												<v-img :src="getImage(order_details.seat_logo.file_uploads)"></v-img>
											</div>
										</v-col>
										<v-col cols="12" lg="9">
											<v-row>
												<v-col class="pt-0 pb-4">
													<span>Size: </span>
													<span class="font-weight-black">{{ order_details.seat_logo.height + 'cm x ' + order_details.seat_logo.width +'cm' }}</span>
												</v-col>
											</v-row>
											<v-row>
												<v-col class="py-0">
													<span>File Name: </span>
													<span class="font-weight-black">{{ order_details.seat_logo.name }}</span>
												</v-col>
											</v-row>
											<v-row>
												<v-col class="py-0">
													<v-container
														v-if="order_details.logo_color"
														style="align-items:center; display:flex;"
														class="pl-0"
													>
														<div 
															style="display:inline-block;"
															class="mr-1"
														>
															<span>Seam Color: </span>
														</div>
														<div 
															style="display:inline-block;"
															class="font-weight-black"
														>
															{{ order_details.logo_color.name }}
														</div>
														<div
															v-ripple
															style="height: 35px;width: 35px;"
															class="mr-3 ml-3"
															:style="'background:'+ computedRGBA(order_details.logo_color) +';  display:inline-block;'"
														>
														</div>
													</v-container>
													<span v-else>Seam Color: -</span>
												</v-col>
											</v-row>
										</v-col>
									</v-row>
								</v-container>
								<p 
									v-else
									class="font-weight-black mt-3 pb-1"
								>
									- No
								</p>						
								<v-divider></v-divider>
							</v-col>
						</v-row>
						<v-row 
							no-gutters
							class="overflow-auto pt-3"
						>
							<v-col 
								cols="12"
								class="my-2"
							>
								<span>Airbag FB</span>
								<p 
									v-if="sd"
									class="mt-3"
								>
									<span
										v-for="(item,i) in sd.airbag_styles_fbs"
										:key="i"
										class="font-weight-black" 
									>
										- {{ item.name }} <br>
									</span>
								</p>

								<p v-else class="font-weight-black mt-3 pb-1">
									-
								</p>
								<v-divider></v-divider>
							</v-col>							
						</v-row>
						<v-row 
							no-gutters
							class="overflow-auto pt-3"
						>
							<v-col 
								cols="12"
								class="my-2"
							>
								<span>Airbag DT</span>
								<p 
									v-if="sd"
									class="mt-3"
								>
									<span
										v-for="(item,i) in sd.airbag_styles_dts"
										:key="i"
										class="font-weight-black" 
									>
										- {{ item.name }} <br>
									</span>
								</p>

								<p v-else class="font-weight-black mt-3 pb-1">
									-
								</p>
								<v-divider></v-divider>
							</v-col>								
						</v-row>
						<v-row 
							no-gutters
							class="overflow-auto pt-3"
						>
							<v-col 
								cols="12"
								class="my-2"
							>
								<span>DT</span>
								<p v-if="sd" class="mt-3">
									<span
										v-for="(item,i) in sd.dt_styles"
										:key="i"
										class="font-weight-black" 
									>
										- {{ item.name }} <br>
									</span>
								</p>
								<p v-else class="font-weight-black mt-3 pb-1">
									-
								</p>
								<v-divider></v-divider>
							</v-col>
						</v-row>
						<v-row 
							no-gutters
							class="overflow-auto pt-3"
						>
							<v-col 
								cols="12"
								class="my-2"
							>
								<span>Driver Seat Side</span>
								<p class="font-weight-black mt-3 pb-1">
									- {{ order_details.driverSeat ? order_details.driverSeat.name : '' }}
								</p>
								<v-divider></v-divider>
							</v-col>
						</v-row>
						<v-row 
							no-gutters
							class="overflow-auto pt-3"
						>
							<v-col 
								cols="12"
								class="my-2"
							>
								<span>Component</span>
								<p class="font-weight-black mt-3 pb-1">
									- {{ order_details.component ? order_details.component.name : 'N/A' }}
								</p>
								<v-divider></v-divider>
							</v-col>	
						</v-row>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" md="2">
				<span>Screenshots</span>
				<div style="height:40em;overflow-y:scroll;scrollbar-width: thin;">
					<v-img 
						:src="order_details.file_uploads.length>0 ? order_details.file_uploads[0].path : null"
						class="screenshots mb-2 mt-2"
					>
					</v-img>
					<div v-for="(part_spec, index) in part_specs" :key="index">
						<v-img 
							:src="part_spec.file_uploads.length>0 ? part_spec.file_uploads[0].path : null"
							class="screenshots mb-2"
						>
						</v-img>
					</div>
				</div>
			</v-col>
		</v-row>
		<v-row 
			no-gutters
			class="overflow-auto pt-3"
		>
			<v-col 
				cols="12"
			>
				<span>Remarks</span>
				<br>
				<v-textarea
					v-model="order_details.dealer_remark"
					:disabled="true"
					filled
					rows="2"
					row-height="3"
					label=""
					class="mt-3"
					no-resize
				></v-textarea>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<span>Part Specs</span>
			</v-col>
		</v-row>
		<v-row 
			no-gutters
		>
			<v-col
				v-for="(item, n) in part_specs" 
				:key="n" 
				cols="12" md="5" 
				class="br ml-md-10 mt-5 d-flex flex-column"
			>
				<span class="font-weight-black mt-3 pb-1">{{ item.material_no + ' - ' + item.material_name }}</span>
				<v-container
					style="align-items:center; display:flex;"
					class="pl-0 ml-5"
				>
					<div
						style="display:inline-block;"
						class="mr-sm-15"
					>
						<span>Color: </span>
					</div>
					<div
						v-ripple
						style="height: 35px;width: 35px;"
						class="mr-3 ml-5"
						:style="'background:' + (item.color ? computedRGBA(item.color) : null) +';  display:inline-block;'"
					>
					</div>
					<div 
						style="display:inline-block;"
					>
						{{ (item.color ? item.color.name : null) + ' - ' + (item.color ? item.color.description : null) }}
					</div>
				</v-container>
				<v-container
					v-if="item.special_material"
					style="align-items:center; display:flex;"
					class="pl-0 ml-5"
				>
					<div
						style="display:inline-block;"
						class="mr-2"
					>
						<span>Special Material:</span>
					</div>
					<div
						v-ripple
						style="height: 35px;width: 35px; display:inline-block;"
						class="mr-3"
					>
						<v-img :src="getImagePath(item.special_material)"></v-img>
					</div>
					<div 
						style="display:inline-block;"
					>
						{{ item.special_material.name }}
					</div>
				</v-container>
				<v-container
					v-if="item.perforation"
					style="align-items:center; display:flex;"
					class="pl-0 ml-5"
				>
					<div
						style="display:inline-block;"
						class="mr-sm-9"
					>
						<span>Perforation:</span>
					</div>
					<div
						v-ripple
						style="height: 35px;width: 35px; display:inline-block;"
						class="mr-3 ml-1"
					>
						<v-img :src="getImagePath(item.perforation)"></v-img>
					</div>
					<div 
						style="display:inline-block;"
					>
						{{ item.perforation.name }}
					</div>
				</v-container>
				<v-container
					v-if="item.perforation"
					style="align-items:center; display:flex;"
					class="pl-0 ml-5"
				>
					<div
						style="display:inline-block;"
						class="mr-sm-8"
					>
						<span>Perforation <br> Seam Color:</span>
					</div>
					<div
						v-ripple
						style="height: 35px;width: 35px;"
						class="mr-3 ml-1"
						:style="'background:'+ computedRGBA(item.perforation_color) +'; display:inline-block;'"
					>
					</div>
					<div 
						style="display:inline-block;"
					>
						{{ item.perforation_color.name + ' - ' + item.perforation_color.description }}
					</div>
				</v-container>
				<v-container
					v-if="item.design_upgrade"
					style="align-items:center; display:flex;"
					class="pl-0 ml-5"
				>
					<div
						style="display:inline-block;"
						class="mr-1"
					>
						<span>Design Upgrade:</span>
					</div>
					<div
						v-ripple
						style="height: 35px;width: 35px; display:inline-block;"
						class="mr-3 ml-1"
					>
						<v-img :src="getImagePath(item.design_upgrade)"></v-img>
					</div>
					<div 
						style="display:inline-block;"
					>
						{{ item.design_upgrade.name }}
					</div>
				</v-container>
				<v-container
					v-if="item.design_upgrade"
					style="align-items:center; display:flex;"
					class="pl-0 ml-5"
				>
					<div
						style="display:inline-block;"
						class="mr-sm-1"
					>
						<span>Design Upgrade <br> Seam Color:</span>
					</div>
					<div
						v-ripple
						style="height: 35px;width: 35px;"
						class="mr-3 ml-1"
						:style="'background:'+ computedRGBA(item.design_upgrade_color) +';  display:inline-block;'"
					>
					</div>
					<div 
						style="display:inline-block;"
					>
						{{ item.design_upgrade_color.name + ' - ' + item.design_upgrade_color.description }}
					</div>
				</v-container>
				<v-spacer></v-spacer>
				<v-divider></v-divider>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { resourceMixin } from "@/mixins/ResourceMixin"
import { errorHandlerMixin } from "@/mixins/ErrorHandlerMixin"
import { mapActions } from "vuex"

const DEFAULT_IMAGE_FILE_TYPE_ID = 11;
const DEFAULT_MASK_FILE_TYPE_ID = 12;
const FILE_TYPE_GLB = 10

const SUCCESS_PAYMENT_STATUS = 1;
const CANCELLED_ORDER_STATUS = 12;

export default {
	components:{
	},
	mixins: [
		resourceMixin,
		errorHandlerMixin,
	],
	data(){
		return {
			order_id: 0,
			order_details: {
				seat_logo: null,
				main_color: null,
				secondary_color: null,
				file_uploads : [],
				payment_status_id: SUCCESS_PAYMENT_STATUS,
				status: {
					id: null
				},
				user: {
					id: null
				},
			},
			sd: null,
			part_specs: [],
			sd_list: [],
			paymentRecord: null,
		}
	},
	computed:{
		hasPaid()
		{
			if(this.order_details.user.id == this.$auth.user().id && this.order_details.payment_status_id != SUCCESS_PAYMENT_STATUS && (!this.order_details.status) && this.paymentRecord)
				return false
			
			return true
		}
	},
	created(){
		this.order_id = this.$route.query.id
		this.getPaymentRecord()
		this.initialize_()
	},
	methods:{
		...mapActions(["setIsCustomization", "setSelectedSeatModel", "setOrderCustomizations", "setPartSpecs", "setOrderOptions"]),
		getResourceData_(page = 1){
			let payload = {
				id: this.order_id,
				page: page,
				itemsPerPage: 5,
			}
			this.model_loading_ = true
			this.$api.getOrderList(payload).then((res)=>{
				let { data, } = res.data.result
				this.resourceDataSet_ = [ ...this.resourceDataSet_, ...data ]
				if(data.length>0)
				{
					this.order_details = data[0]
					this.getModelCodeOption()
					this.sd = this.order_details ? this.order_details.sd : null
					this.part_specs = this.order_details ? this.order_details.part_specs : null
					this.loadSeamImage('/' + this.order_details.seam.name + '.png', this.order_details.seam_color, this.order_details.piping_color)
				}		
			}).catch((err)=>{
				this.errorHandler_(err)
			}).finally(()=>{
				this.model_loading_ = false
			})
		},
		getPaymentRecord(){
			let payload = {
				order_id: this.order_id,
			}
			this.$api.getPaymentRecord(payload).then((res)=>{
				let { data, } = res.data.result
				this.paymentRecord = data[0]
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		computedRGBA(item){
			if(item)
				return `rgba(${item.r}, ${item.g}, ${item.b}, ${item.a});`
		},
		getImage(images)
		{
			let img = images.filter((file)=> file.mime_type == "image/png")
			if(img)
				return img[0].path
			else
				return null
		},
		getImagePath(val)
		{
			return val ? (val.file_uploads.find((item)=>item.file_upload_type_id == DEFAULT_IMAGE_FILE_TYPE_ID) ? val.file_uploads.find((item)=>item.file_upload_type_id == DEFAULT_IMAGE_FILE_TYPE_ID).path : null) : null
		},
		getMaskPath(val)
		{
			return val ? (val.file_uploads.find((item)=>item.file_upload_type_id == DEFAULT_MASK_FILE_TYPE_ID) ? val.file_uploads.find((item)=>item.file_upload_type_id == DEFAULT_MASK_FILE_TYPE_ID).path : null) : null
		},
		loadSeamImage(url, rgba_seam = '', rgba_piping = ''){
			this.isLoadedPiping = false
			var imageMask = new Image();
			imageMask.crossOrigin = "Anonymous";
			let _this = this
			
			imageMask.onload = function(){
				_this.drawImage(this, rgba_seam, rgba_piping);
				_this.isLoadedSeam = true
			};

			imageMask.src = url
		},
		drawImage(imageObj, rgba_seam, rgba_piping){
			var canvas = document.getElementById("seamWindow");
			var context = canvas.getContext("2d");

			var destX = 0;
			var destY = 0;

			canvas.width = 100
			canvas.height = 100
			context.drawImage(imageObj, destX, destY, canvas.width, canvas.height);

			var imageData = context.getImageData(0, 0, canvas.width, canvas.height);

			let seamColor = [230, 234, 237]
			let pipingColor = [230, 234, 237]

			if(rgba_seam)
				seamColor = [rgba_seam.r, rgba_seam.g, rgba_seam.b]

			if(rgba_piping)
				pipingColor = [rgba_piping.r, rgba_piping.g, rgba_piping.b]

			for (var i = 0; i < imageData.data.length; i += 4) {
				if(imageData.data[i] >= 0 && imageData.data[i + 1] >=140 && imageData.data[i + 2] >= 0)
				{
					imageData.data[i] = seamColor[0];
					imageData.data[i + 1] = seamColor[1];
					imageData.data[i + 2] = seamColor[2];
				}
				else if(imageData.data[i] >= 140 && imageData.data[i + 1] >= 0 && imageData.data[i + 2] >= 0)
				{
					imageData.data[i] = pipingColor[0];
					imageData.data[i + 1] = pipingColor[1];
					imageData.data[i + 2] = pipingColor[2];
				}
			}

			// overwrite original image
			context.putImageData(imageData, destX, destY);
		},
		reorder()
		{
			if(this.sd_list[0])
			{
				this.setSelectedSeatModel(this.sd_list[0])
				this.prepareOrderCustomizationJson()
				this.preparePartSpecJson()
				this.prepareOrderOptionsJson()
				this.setIsCustomization(true)
				this.$router.push("/new-order/customization")
			}
		},
		getModelCodeOption(){
			let payload = {
				id: this.order_details.sd_id,
				glb_only: 1,
				is_archived: 0,
			}
				
			this.model_loading_ = true
			this.$api.getSDList(payload).then((res)=>{
				let { data, total } = res.data.result
				this.sd_list = data
				this.sd_list.forEach((item)=>{
					item.glbSeat = item.file_uploads.find((file)=> file.file_upload_type_id == FILE_TYPE_GLB)
				})
			}).catch((err)=>{
				this.errorHandler_(err)
			}).finally(()=>{
				this.model_loading_ = false
			})
		},
		prepareOrderCustomizationJson()
		{
			let order_details = this.order_details
			let form = {
				package: order_details.package.name,
				package_id: order_details.package_id,
				seam: {
					value: order_details.seam_id,
					title: order_details.seam.name,
					seamColor: order_details.seam_color,
					pipingColor: order_details.piping_color
				},
				logo: order_details.seat_logo,
				mainColor: order_details.main_color,
				secondaryColor: order_details.secondary_color,
				driverSeat: {
					id: null,
				},
				component: null,
				remark: null,
				screenshot: null,
				estimatedTimeDelivery: null,
				paymentMethod: null,
				shipmentMethod: null,
				deliveryType: 'new',
				billingAddressType: 'new',
				deliveryAddress: {
					deliveryAddress:  null,
					deliveryAddress2: null,
					deliveryCity:  null,
					deliveryPostcode:  null,
					deliveryState:  null,
					deliveryCountry:  null,
				},
				billingAddress: {
					billingAddress:  null,
					billingAddress2: null,
					billingCity:  null,
					billingPostcode:  null,
					billingState:  null,
					billingCountry:  null,
				},
				orderQty: 1,
				purchaseOrder: null,
			}
			
			this.setOrderCustomizations(form)
		},
		preparePartSpecJson()
		{
			let order_specs = this.order_details.part_specs
			let part_specs = []
			for(let order_spec of order_specs)
			{
				let part_spec = {
					material_no: order_spec.material_no,
					material_no_web: order_spec.sd_socket.material_no_web,
					name: order_spec.material_name,
					color: order_spec.color
				}

				if(order_spec.special_material)
				{
					part_spec.special_material = order_spec.special_material
					part_spec.special_material.image_url = this.getImagePath(order_spec.special_material)
					part_spec.special_material.mask_url = this.getMaskPath(order_spec.special_material)
				}

				if(order_spec.design_upgrade)
				{
					if(order_spec.design_upgrade.type == "pd")
					{
						part_spec.pattern_stitching = {}
						part_spec.pattern_stitching.pattern_stitching_details = order_spec.design_upgrade
						part_spec.pattern_stitching.pattern_stitching_details.image_url = this.getImagePath(order_spec.design_upgrade)
						part_spec.pattern_stitching.pattern_stitching_details.mask_url = this.getMaskPath(order_spec.design_upgrade)
						
						if(order_spec.design_upgrade_color)
							part_spec.pattern_stitching.color = order_spec.design_upgrade_color
					}
					else if(order_spec.design_upgrade.type == "pf")
					{
						part_spec.digital_punching = {}
						part_spec.digital_punching.digital_punching_details = order_spec.design_upgrade
						part_spec.digital_punching.digital_punching_details.image_url = this.getImagePath(order_spec.design_upgrade)
						part_spec.digital_punching.digital_punching_details.mask_url = this.getMaskPath(order_spec.design_upgrade)

						if(order_spec.design_upgrade_color)
							part_spec.digital_punching.color = order_spec.design_upgrade_color
					}
				}

				if(order_spec.perforation)
				{
					if(order_spec.perforation.type == "normal_perforation")
					{
						part_spec.perforation = {}
						part_spec.perforation = order_spec.perforation
						part_spec.perforation.image_url = this.getImagePath(order_spec.perforation)
						part_spec.perforation.mask_url = this.getMaskPath(order_spec.perforation)
					}
					else if(order_spec.perforation.type == "psp")
					{
						part_spec.psp = {}
						part_spec.psp.psp_details = order_spec.perforation
						part_spec.psp.psp_details.image_url = this.getImagePath(order_spec.perforation)
						part_spec.psp.psp_details.mask_url = this.getMaskPath(order_spec.perforation)

						if(order_spec.perforation_color)
							part_spec.psp.color = order_spec.perforation_color
					}
				}

				part_specs.push(part_spec)
			}

			this.setPartSpecs(part_specs)
		},
		prepareOrderOptionsJson()
		{
			let order_options = {}

			order_options = {
				remarks: this.order_details.dealer_remark,
				logoSeamColor: this.order_details.logo_color,
				driverSeat: this.order_details.driver_seat,
				component: this.order_details.component,
			}

			this.setOrderOptions(order_options)
		},
		payNow()
		{
			let payload = {
				order_id: this.order_id,
				currency: this.paymentRecord.request_meta ? (this.paymentRecord.request_meta.currency ? this.paymentRecord.request_meta.currency : "MYR") : "MYR",
				amount: this.paymentRecord.amount,
				platform: process.env.VUE_APP_PAYMENT_PLATFORM,
				type_id: this.paymentRecord.type_id,
				mobile_no: this.paymentRecord.request_meta ? this.paymentRecord.request_meta.userContact : null
			}
			this.$api.getPaymentGateway(payload).then((res)=>{
				let url = res.data.payment_url
				window.location.href = url;
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		async confrimCancelOrder(){
			const res = await this.$dialog.confirm({
				color: 'red',
				text: `
					<div class="pt-2">
						<div class="mb-2 font-weight-bold">Are you sure to continue?</div> 
						<div class="caption font-italic">
							Do you want to cancel this order? This action is cannot be undone.
						</div>
					</div>
				`,
				title: 'Warning'
			})

			if(res){
				this.cancelOrder()
			}
		},
		cancelOrder()
		{
			let payload = {
				id: this.order_id,
				status: CANCELLED_ORDER_STATUS
			}

			this.$api.updateOrder(payload).then((res)=>{
				this.initialize_()
				this.$toast.success("Order cancelled successfully!")
			}).catch((err)=>{
				this.errorHandler_(err)
				this.$toast.success("Something went wrong!")
			})
		}
	}
}
</script>
<style>
.seamWindow{
	width: 35px;
	height: 35px;
}
</style>
